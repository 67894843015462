<template>
  <b-container class="bg-white py-4">
    <b-row v-if="!permissionEditRisk">
      <b-col>
        <b-card>
          <h4>You do not have access permission for this page</h4>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="permissionEditRisk && !loadingRisk">
      <b-col>
        <b-container>
          <b-row class="mb-4">
            <b-col>
              <router-link :to="{ name: 'Risk', params: { id: risk.id }}">back to risk page</router-link>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col>
              <div class="mb-2"><strong>Name</strong></div>
              <div v-if="!edit.name" class="mb-2">
                {{risk.name}}
                <b-button variant="outline-primary" size="sm" v-on:click="edit.name = true">Edit</b-button>
              </div>
                <b-form-input v-if="edit.name" id="titleInput"
                                v-model="risk.name"
                                placeholder="Risk name"
                                required
                                class="mb-2">
                </b-form-input>
                <b-button v-if="validateTitle" class="mr-2" variant="primary" size="sm" v-on:click="saveProperty('name')">Save</b-button>
                <b-button v-if="edit.name" variant="outline-primary" size="sm" v-on:click="cancelSaveProperty('name')">Cancel</b-button>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col>
              <div class="mb-2"><strong>Definition</strong></div>
              <div v-if="!edit.comments" class="mb-2">
                <markdown :content="risk.comments" />
                <b-button variant="outline-primary" size="sm" v-on:click="edit.comments = true">Edit</b-button>
              </div>
                <b-form-textarea v-if="edit.comments" id="detailsInput"
                                v-model="risk.comments"
                                placeholder="Risk definition"
                                required
                                :rows=10
                                class="mb-2">
                </b-form-textarea>
                <span v-if="!saving">
                <b-button v-if="validateComments" class="mr-2" variant="primary" size="sm" v-on:click="saveProperty('comments')">Save</b-button>
                <b-button v-if="edit.comments" variant="outline-primary" size="sm" v-on:click="cancelSaveProperty('comments')">Cancel</b-button>
                </span>
                <span v-if="saving"><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></span>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col>
              <div class="mb-2"><strong>Context</strong></div>
              <div v-if="!edit.context" class="mb-2">
                <markdown :content="risk.context" />
                <b-button variant="outline-primary" size="sm" v-on:click="edit.context = true">Edit</b-button>
              </div>
                <b-form-textarea v-if="edit.context" id="detailsInput"
                                v-model="risk.context"
                                placeholder="Context"
                                required
                                :rows=10
                                class="mb-2">
                </b-form-textarea>
                <span v-if="!saving">
                <b-button v-if="validateContext" class="mr-2" variant="primary" size="sm" v-on:click="saveProperty('context')">Save</b-button>
                <b-button v-if="edit.context" variant="outline-primary" size="sm" v-on:click="cancelSaveProperty('context')">Cancel</b-button>
                </span>
                <span v-if="saving"><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></span>
            </b-col>
          </b-row>

          <b-row class="mb-4">
            <b-col>
              <edge-select-list
                v-if="!loading"
                directionProp="to vertex"
                edgeLabelProp="detects"
                :listSourceProp="listSourcePropAlerts"
                nameProp="Relevant alerts"
                :vertexProp="risk"
                />
            </b-col>
          </b-row>

          <b-row class="mb-4">
            <b-col>
              <edge-select-list
                v-if="!loading"
                directionProp="to vertex"
                edgeLabelProp="defines"
                :listSourceProp="listSourcePropRegbites"
                nameProp="Regulatory source"
                :vertexProp="risk"
                />
            </b-col>
          </b-row>

          <b-row class="my-4">
            <b-col>
              <h5>Deleting risk</h5>
              <b-button variant="danger" v-on:click="deleteRisk">Delete risk</b-button>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import ac from '../libs/accesscontrol'

import Markdown from '@/components/Markdown.vue'
import EdgeSelectList from '@/components/cosmos/EdgeSelectList.vue'

export default {
  name: 'RiskEdit',
  beforeDestroy () {},
  components: {
    EdgeSelectList,
    Markdown
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    },
    validateComments: function () {
      return this.edit.comments && this.risk.comments
    },
    validateContext: function () {
      return this.edit.context
    },
    validateTitle: function () {
      return this.edit.name && this.risk.name
    }
  },
  created: function () {
    this.permissionEditRisk = ac.can(this.user.acgroups).createAny('marrisk').granted
    this.loadRisk()
  },
  data () {
    return {
      edit: {
        comments: false,
        name: false,
        context: false
      },
      listSourcePropAlerts: { partition: 'events', label: 'alert' },
      listSourcePropRegbites: { partition: 'events', label: 'regbite' },
      loadingRisk: true,
      permissionEditRisk: false,
      saving: false,
      risk: {
        name: '',
        comments: '',
        context: ''
      },
      riskOld: {}
    }
  },
  methods: {
    cancelSaveProperty: function (property) {
      this.$logger.debug('cancel save property')
      this.risk[property] = this.riskOld[property]
      this.edit[property] = false
    },
    deleteRisk: async function () {
      this.$logger.debug('deleteRisk started')
      this.saving = true
      try {
        // let response = await this.$http(config)
        let apiName = 'cosmos'
        let path = `/risk`
        let params = {
          body: {
            id: this.risk.id
          }
        }
        let response = await this.$Amplify.API.del(apiName, path, params)
        this.$logger.debug('deleted OK:', response)
        this.$router.push({ name: 'Risks' })
      } catch (e) {
        this.$logger.warn('delete error: ' + e)
      }
      this.saving = false
    },
    loadRisk: async function () {
      this.$logger.debug('loadRisks started')
      this.loadingRisk = true
      try {
        let apiName = 'cosmos'
        let path = `/risk/${this.$route.params.id}`
        let response = await this.$Amplify.API.get(apiName, path)
        this.risk = response
        this.riskOld.name = this.risk.name
        this.riskOld.comments = this.risk.comments
        this.riskOld.context = this.risk.context
        this.loadingRisk = false
      } catch (e) {
        this.$logger.warn('/risk/:id error', e)
      }
    },
    saveProperty: async function (property) {
      this.$logger.debug(`saving ${property} start`)
      this.saving = true
      try {
        // let response = await this.$http(config)
        let apiName = 'cosmos'
        let path = `/property`
        let params = {
          body: {
            property: {
              partition: 'events',
              id: this.risk.id,
              propertyName: property,
              propertyValue: this.risk[property],
              table: 'risks'
            }
          }
        }
        let response = await this.$Amplify.API.put(apiName, path, params)
        this.$logger.debug('saved OK:', response)
        this.edit[property] = false
        this.riskOld[property] = this.risk[property]
      } catch (e) {
        this.$logger.warn('event saving error: ' + e)
      }
      this.saving = false
    }
  }
}
</script>

<style>
</style>
